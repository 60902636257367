import React from "react";
import { useState, useRef } from "react";
import "./App.css";
import emailjs from "@emailjs/browser";
import Contact from "./Contact";
import { useNavigate } from "react-router-dom";
import "./Fhd.css";

function App() {
  const [isOpen, setIsOpen] = useState(false);

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  return (
    <>
      <header>
        <img className="header-logo" src="logosvg.svg" alt="" />
        <div className="header-nav">
          <a className="header-info-btn" href="#o-nas">
            O NAS
          </a>
          <div
            className="header-dropdown-uslugi"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <button className="header-uslugi-dropdown-btn">USŁUGI</button>
            {isOpen && (
              <ul className="header-dropdown-menu list">
                <li className="header-dropdown-item item">
                  <a className="header-item-text" href="#Wynajem">
                    Wynajem
                  </a>
                </li>
                <li className="header-dropdown-item item">
                  <a className="header-item-text" href="#Sprzedaż_i_Zakup">
                    Sprzedaż i Zakup
                  </a>
                </li>
                <li className="header-dropdown-item item">
                  <a className="header-item-text" href="#Zarządzanie">
                    Zarządzanie
                  </a>
                </li>
              </ul>
            )}
          </div>

          <a className="header-zespol-btn" href="#team">
            ZESPÓŁ
          </a>
          <a className="header-kontakt-btn" href="#Kontakt">
            KONTAKT
          </a>
        </div>
        <button className="header-num-btn">
          <a className="header-num-btn-text" href="#Kontakt">
            ZAPLANUJ KONSULTACJĘ
          </a>
        </button>
      </header>
      <div className="bg-fhd"></div>
      <div id="body" className="body">
        <div className="bg-desktop">
          <div className="main">
            <h2 className="main-info-text">
              Wasz <span className="main-info-text-italic">interes —</span> Nasz
              <span className="main-info-text-italic"> priorytet</span>
            </h2>
            <p className="main-text-under-info">
              Łączymy doświadczenie z nowoczesnością, zapewniając Ci kompleksową
              obsługę na każdym etapie.
            </p>

            <button className="main-more-btn">
              <a className="main-more-btn-text" href="#o-nas-info">
                DOWIEDZ SIĘ WIĘCEJ
              </a>
            </button>
          </div>
          <div className="main-o-nas">
            <div className="main-o-nas-text-img">
              <img className="main-o-nas-logo" src="/logo2.png" alt="" />
              <div className="main-o-nas-about">
                <h2 id="o-nas" className="main-o-nas-nav">
                  O nas
                </h2>
                <p className="main-o-nas-under-nav">
                  Stawiamy na profesjonalizm, indywidualne podejście i
                  długotrwałe relacje z naszymi klientami. <br /> <br />
                  Niezależnie, czy chodzi o wynajem, sprzedaż, czy zakup
                  nieruchomości – zapewniamy pełną obsługę na każdym etapie.
                </p>
                <button className="main-o-nas-know">
                  {" "}
                  <a className="main-o-nas-know-text" href="#team">
                    Poznaj nas bliżej
                  </a>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-pracy">
          <div className="main-advantages">
            <div className="main-advantages-all-form">
              <h1 className="main-advantages-hero-text">
                Plusy Pracy z{" "}
                <i>
                  {" "}
                  Jedną <br />
                  Stroną{" "}
                </i>{" "}
                Transakcji
              </h1>
              <div className="main-advantages-form">
                <div className="main-advantages-form-text">
                  <h4 className="main-advantages-form-name">
                    Negocjujemy w Państwa interesie
                  </h4>
                  <p className="main-advantages-form-under-name1">
                    Dzięki naszemu doświadczeniu i znajomości rynku, jesteśmy w
                    stanie zaoszczędzić zarówno Państwa czas, jak i pieniądze.
                    Działamy jako Państwa reprezentanci, dbając o to, aby każda
                    decyzja była korzystna i przemyślana.
                  </p>
                </div>
                <img
                  className="main-advantages-form-img"
                  src="./bargain.png"
                  alt=""
                />
              </div>
              <div className="main-advantages-form">
                <div className="main-advantages-form-text2">
                  <h4 className="main-advantages-form-name">
                    Więcej potencjalnych klientów
                  </h4>
                  <p className="main-advantages-form-under-name2">
                    Pracując z nami, zyskują Państwo dostęp do szerokiej sieci
                    klientów i nowoczesnych narzędzi marketingowych, które
                    przyspieszają sprzedaż lub wynajem i zwiększają
                    zainteresowanie ofertą.
                  </p>
                </div>
                <img
                  className="main-advantages-form-img2"
                  src="./traffic.png"
                  alt=""
                />
              </div>
              <div className="main-advantages-form">
                <div className="main-advantages-form-text3">
                  <h4 className="main-advantages-form-name">
                    Szybsze znalezienie klienta
                  </h4>
                  <p className="main-advantages-form-under-name">
                    Nasze skuteczne działania promocyjne przyspieszają proces
                    znalezienia odpowiedniego klienta. Dzięki temu nieruchomość
                    nie stoi na rynku zbyt długo, a właściciel unika strat
                    finansowych.
                  </p>
                </div>
                <img
                  className="main-advantages-form-img3"
                  src="./time.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="main-o-nas-con" id="o-nas-info">
            <div className="o-nas-con-info">
              <img className="o-nas-con-img" src="/check.png" alt="" />
              <div className="o-nas-con-text">
                <h4 className="o-nas-con-name">
                  Pracujemy <i> tylko </i> dla Ciebie
                </h4>
                <p className="o-nas-con-under-name">
                  Pracujemy w interesie tylko jednej strony transakcji, co
                  pozwala nam zapewnić najlepsze warunki dla naszych klientów.
                </p>
              </div>
            </div>
            <div className="o-nas-con-info2">
              <img className="o-nas-con-img" src="/check.png" alt="" />
              <div className="o-nas-con-text">
                <h4 className="o-nas-con-name">Indywidualne podejście</h4>
                <p className="o-nas-con-under-name2">
                  Pracujemy przede wszystkim z ludźmi, a nie z nieruchomościami.
                  Dopasowujemy współpracę do Państwa potrzeb.
                </p>
              </div>
            </div>
            <div className="o-nas-con-info">
              <img className="o-nas-con-img" src="/check.png" alt="" />
              <div className="o-nas-con-text">
                <h4 className="o-nas-con-name">Kompleksowa obsługa</h4>
                <p className="o-nas-con-under-name">
                  Bez względu na to, czy kupujecie, wynajmujecie, czy
                  sprzedajecie, możecie liczyć na naszą kompleksową obsługę na
                  każdym etapie.
                </p>
              </div>
            </div>
            <div className="o-nas-con-info3">
              <img className="o-nas-con-img" src="/check.png" alt="" />
              <div className="o-nas-con-text">
                <h4 className="o-nas-con-name">Nowoczesne rozwiązania</h4>
                <p className="o-nas-con-under-name4">
                  Możemy zrealizować wszystko zdalnie, bez konieczności spotkań,
                  oszczędzając Twój czas.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="main-uslugi">
          <div className="main-parther1">
            <div className="main-partner-text">
              <h2 id="uslugi" className="main-partner-need-name1">
                Wybierz partnera, który rozumie {" "}
                <i className="Twoje"> Twoje </i> potrzeby
              </h2>
              <p className="main-partner-need-under-name1">
                Nieruchomości – to inwestycje w komfort, bezpieczeństwo i
                przyszłość. Z nami każdy krok staje się prostszy i bardziej
                przewidywalny.
              </p>
            </div>
          </div>
          <div className="main-parther">
            <div className="main-partner-text">
              <h2 id="Wynajem" className="main-partner-need-name2">
                WYNAJEM
              </h2>
              <p className="main-partner-need-under-name2">
                Znajdź idealnego najemcę lub wymarzone mieszkanie z nami.
                <br />
                <br />
                Bez zbędnych formalności, tylko szybkie, efektywne wyniki i
                długoterminowe wsparcie.
              </p>
              <button className="main-parther-need-btn">
                <a className="main-parther-need-btn-text" href="#Kontakt">
                  UMÓW SPOTKANIE
                </a>
              </button>
            </div>
            <img className="main-parther-need-img" src="./dom1.png" alt="" />
          </div>
          <div className="main-parther">
            <img
              className="main-parther-need-img3"
              src="./pather1.png"
              alt=""
            />
            <div className="main-partner-text">
              <h2 id="Sprzedaż_i_Zakup" className="main-partner-need-name3">
                Sprzedaż i Zakup
              </h2>
              <p className="main-partner-need-under-name3">
                Kupuj lub sprzedawaj bez stresu – z nami to proste.
                <br />
                <br />
                Bez przeciągania procesu, zapewniamy szybkie transakcje i pełne
                wsparcie na każdym kroku.
              </p>
              <button className="main-parther-need-btn">
                <a className="main-parther-need-btn-text" href="#Kontakt">
                  UMÓW SPOTKANIE
                </a>
              </button>
            </div>
          </div>
          <div className="main-parther">
            <div className="main-partner-text">
              <h2 id="Zarządzanie" className="main-partner-need-name4">
                ZARZĄDANIE
              </h2>
              <p className="main-partner-need-under-name4">
                Oddaj zarządzanie nieruchomością w ręce ekspertów.
                <br />
                <br />
                Bez zbędnych zmartwień, dbamy o każdy szczegół, byś mógł cieszyć
                się spokojem i pewnością.
              </p>
              <button className="main-parther-need-btn">
                <a className="main-parther-need-btn-text" href="#Kontakt">
                  UMÓW SPOTKANIE
                </a>
              </button>
            </div>
            <img className="main-parther-need-img" src="./img2.png" alt="" />
          </div>
        </div>
        <div className="main-team">
          <div className="main-team-info">
            <h1 className="main-team-info-name">
              Nasz zespół – <em>Twoi eksperci</em>
            </h1>
            <p className="main-team-info-under-name">
              Za każdą transakcją stoi zespół pełen pasji i doświadczenia.
              Jesteśmy profesjonalistami, którzy rozumieją Twoje potrzeby i
              dbają o każdy szczegół.
            </p>
          </div>
        </div>
        <div id="team" className="main-team-member-con">
          <div className="main-team-member">
            <img className="main-team-member-img" src="./noimg.png" alt="" />
            <h3 className="main-team-member-name">Izabela Łubiarz</h3>
            <h4 className="main-team-member-under-name">
              Ekspert ds. Nieruchomości
            </h4>
            <p className="main-team-member-text-info">
              Specjalistka ds. sprzedaży oraz wynajmu nieruchomości w Krakowie.
            </p>
            <button className="main-team-member-btn">
              <a className="main-team-member-btn-text" href="#Kontakt">
                ZAPŁĄNUJ KONSULTACJĘ
              </a>
            </button>
          </div>
          <div className="main-team-member">
            <img className="main-team-member-img2" src="./member.png" alt="" />
            <h3 className="main-team-member-name2">Michał Szewczyk</h3>
            <h4 className="main-team-member-under-name2">
              Ekspert ds. Nieruchomości
            </h4>
            <p className="main-team-member-text-info2">
              Specjalista ds. sprzedaży oraz wynajmu nieruchomości w Krakowie.
            </p>
            <button className="main-team-member-btn">
              <a className="main-team-member-btn-text" href="#Kontakt">
                ZAPŁĄNUJ KONSULTACJĘ
              </a>
            </button>
          </div>
          <div className="main-team-member">
            <img className="main-team-member-img3" src="./noimg.png" alt="" />
            <h3 className="main-team-member-name3">Anna Riabczenko</h3>
            <h4 className="main-team-member-under-name3">
              Ekspert ds. Nieruchomości
            </h4>
            <p className="main-team-member-text-info3">
              Specjalistka ds. sprzedaży oraz wynajmu nieruchomości w Krakowie.
            </p>
            <button className="main-team-member-btn">
              <a className="main-team-member-btn-text" href="#Kontakt">
                ZAPŁĄNUJ KONSULTACJĘ
              </a>
            </button>
          </div>
        </div>
        <div className="main-form">
          <Contact />
        </div>
      </div>
      <footer>
        <div className="footer-column">
          <div className="footer-link">
            <a className="footer-links" href="#body">
              Głowna strona
            </a>
            <a className="footer-links" href="#o-nas">
              O nas
            </a>
            <a className="footer-links" href="#team">
              Nasz zespół
            </a>
            <a className="footer-links" href="#Kontakt">
              Kontakt
            </a>
          </div>
          <div className="footer-link">
            <a className="footer-links" href="#uslugi">
              Usługi
            </a>
            <a className="footer-links" href="#Wynajem">
              Wynajem
            </a>
            <a className="footer-links" href="#Sprzedaż_i_Zakup">
              Sprzedaż i Zakup
            </a>
            <a className="footer-links" href="#Zarządzanie">
              Zarządzanie
            </a>
          </div>
        </div>
        <div className="footer-info">
          <p className="footer-info-text">New Home Agency sp. z o.o.</p>
          <p className="footer-info-text">
            ul. Biskupa Albina Małysiaka 26/15 30-389 Kraków
          </p>
          <a className="footer-info-text" href="tel:+48577132200">
            +48 577 132 200
          </a>
        </div>
        <img className="footer-logo" src="./logofooter.png" alt="" />
      </footer>
    </>
  );
}

export default App;
